import React, { memo, useContext, useEffect, useState, useRef } from 'react';
import ModalContext from '@/contexts/ModalContext';
import { useTranslation } from 'react-i18next';
import Button from '@/components/shared/Button';
import { Formik, ErrorMessage } from 'formik';
import ShortUniqueId from 'short-unique-id';
import { toast } from 'react-toastify';
import DatabaseContext from '../contexts/DatabaseContext';
import ModalEvents from '../constants/ModalEvents';
import BaseModal from './BaseModal';
import schema from './validation';

const AddCompanyModal = () => {
  const { updateCompanyInfo, createCompanyInfo } = useContext(DatabaseContext);
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { emitter } = useContext(ModalContext);
  const event = ModalEvents.ADD_COMPANY_MODAL;
  const [companyInfo, setCompanyInfo] = useState();

  useEffect(() => {
    const unbind = emitter.on(event, (payload) => {
      setOpen(true);
      if (payload) {
        setCompanyInfo(payload);
      } else {
        setCompanyInfo(null);
      }
    });

    return () => unbind();
  }, [emitter, event]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await schema.validate(values);
      const id = uuid();
      if (!companyInfo?.id) {
        values.id = `c_${id}`;
        await createCompanyInfo(values);
        window.location.reload(true);
      } else {
        await updateCompanyInfo(values);
      }

      modalRef.current.handleClose();
    } catch (error) {
      toast.error(error.message);
    }

    setLoading(false);
  };
  const submitAction = (values) => (
    <Button type="submit" isLoading={loading} onClick={() => onSubmit(values)}>
      {loading ? t('shared.buttons.loading') : 'Submit'}
    </Button>
  );

  return (
    <div>
      <Formik
        validateOnBlur
        enableReinitialize
        validationSchema={schema}
        initialValues={companyInfo || {}}
      >
        {({ values, handleChange, handleSubmit, resetForm }) => (
          <BaseModal
            title="Add Compnay Info"
            ref={modalRef}
            state={[open, setOpen]}
            action={submitAction(values)}
            onDestroy={resetForm}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-2xl grid grid-cols-2 gap-8">
                <div>
                  <label htmlFor="name">
                    <div className="text-2xl">Name *</div>
                    <div className="relative grid items-center">
                      <input
                        id="name"
                        name="name"
                        value={values.name || ''}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </label>
                </div>
                <ErrorMessage name="name" component="div" />
                <div>
                  <label htmlFor="city">
                    <div className="text-2xl">City</div>
                    <div className="relative grid items-center">
                      <input
                        id="city"
                        name="city"
                        value={values.city || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="email">
                    <div className="text-2xl">Company Email *</div>
                    <div className="relative grid items-center">
                      <input
                        id="email"
                        name="email"
                        value={values.email || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="orgNumber">
                    <div className="text-2xl">Org Number *</div>
                    <div className="relative grid items-center">
                      <input
                        id="orgNumber"
                        name="orgNumber"
                        value={values.orgNumber || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="adminEmail">
                    <div className="text-2xl">Admin Email *</div>
                    <div className="relative grid items-center">
                      <input
                        id="adminEmail"
                        name="adminEmail"
                        value={values.adminEmail || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="phone">
                    <div className="text-2xl">Phone *</div>
                    <div className="relative grid items-center">
                      <input
                        id="phone"
                        name="phone"
                        value={values.phone || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="website">
                    <div className="text-2xl">Website</div>
                    <div className="relative grid items-center">
                      <input
                        id="website"
                        name="website"
                        value={values.website || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
          </BaseModal>
        )}
      </Formik>
    </div>
  );
};

export default memo(AddCompanyModal);
