import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { getFieldProps } from '../utils';
import DataModal from './DataModal';
import DatabaseContext from '../contexts/DatabaseContext';
import Input from '../components/shared/Input';
import ModalEvents from '../constants/ModalEvents';

const initialValues = {
  name: '',
};

const ResumeModal = () => {
  const { t } = useTranslation();
  const { createResume, updateResume } = useContext(DatabaseContext);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const companiesRef = 'companies';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          firebase.database().ref(socketRef).off();
        }
      });

    firebase
      .database()
      .ref(companiesRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const arr = [];
          const data = snapshot.val();

          Object.keys(data).forEach((key) => arr.push(data[key]));
          setCompanies(arr);
        }
      });

    return () => {
      firebase.database().ref(companiesRef).off();
    };
  }, []);

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(5, t('shared.forms.validation.min', { number: 5 }))
      .required(t('shared.forms.validation.required')),
  });

  const options = [
    {
      label: 'Please choose',
      value: '',
    },
  ];

  companies.forEach((element) => {
    options.push({
      label: element.name,
      value: element.id,
    });
  });

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formik) => (
        <DataModal
          title={{
            create: t('dashboard.createResume'),
            edit: t('dashboard.editResume'),
          }}
          onEdit={updateResume}
          onCreate={createResume}
          event={ModalEvents.CREATE_RESUME_MODAL}
        >
          <Input
            label={t('shared.forms.name')}
            className="mb-8 text-2xl"
            placeholder="FullStack Web Developer"
            {...getFieldProps(formik, schema, 'name')}
          />

          <p className="leading-loose text-xl">{t('dashboard.helpText')}</p>
        </DataModal>
      )}
    </Formik>
  );
};

export default memo(ResumeModal);
