import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  orgNumber: Yup.string()
    .matches(/^\d{6}-\d{4}$/, 'OrgNumber must be in the format xxxxxx-xxxx')
    .required('OrgNumber is required'),
  email: Yup.string().required('Company Email is required'),
  phone: Yup.string().required('Compnay phone number is required'),
  adminEmail: Yup.string().required('AdminEmail is required'),
});

export default schema;
