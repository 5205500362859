import { clone } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import download from 'downloadjs';
import { useSelector } from '../../contexts/ResumeContext';
import BaseModal from '../BaseModal';
import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';

const ExportModal = () => {
  const state = useSelector();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.EXPORT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleExportToJson = () => {
    const backupObj = clone(state);
    delete backupObj.id;
    delete backupObj.user;
    delete backupObj.name;
    delete backupObj.createdAt;
    delete backupObj.updatedAt;
    delete backupObj?.companyId;
    const data = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(backupObj, null, '\t'),
    )}`;
    download(data, `Resume-${state.id}.json`, 'text/json');
  };

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.export.heading')}
    >
      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t('modals.export.jsonFormat.heading')}
        </h5>
        <div className="mt-5">
          <Button onClick={handleExportToJson}>
            {t('modals.export.jsonFormat.button')}
          </Button>
          <a id="downloadAnchor" className="hidden">
            {t('modals.export.jsonFormat.button')}
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(ExportModal);
