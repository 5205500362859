import React, { memo, useContext, useEffect, useState } from 'react';
import ModalContext from '@/contexts/ModalContext';
import Button from '@/components/shared/Button';
import firebase from 'gatsby-plugin-firebase';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import ModalEvents from '../constants/ModalEvents';
import BaseModal from './BaseModal';
import DatabaseContext from '../contexts/DatabaseContext';

const AddManagerAuthModal = () => {
  const { getUserInfo, updateUserInfo } = useContext(DatabaseContext);
  const [managerCompanyId, setManagerCompanyId] = useState(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { emitter } = useContext(ModalContext);
  const event = ModalEvents.ADD_MANAGER_AUTH_MODAL;

  useEffect(() => {
    const unbind = emitter.on(event, (payload) => {
      setOpen(true);
      setManagerCompanyId(payload.managerCompanyId);
    });

    return () => unbind();
  }, [emitter, event]);

  const addManagerAccount = async () => {
    setLoading(true);
    const url = `https://happycv.se`;
    const { currentUser } = firebase.auth();
    const sendMail = firebase.functions().httpsCallable('sendMail');
    const managerEmail = value.trim();

    try {
      const userInfo = await getUserInfo(managerEmail);
      if (userInfo.adminCompanyId || userInfo.managerCompanyId) {
        toast.error(
          'The user is the Admin or Manager of another company, please try a different email.',
        );
      } else {
        userInfo.managerCompanyId = managerCompanyId;
        await updateUserInfo(userInfo);
        setOpen(false);
        setValue(null);
      }
    } catch (e) {
      try {
        const managerInfo = {
          uid: uuidv4(),
          email: value,
          managerCompanyId,
          temp: true,
        };
        await firebase
          .database()
          .ref(`users/${managerInfo.uid}`)
          .set(managerInfo);
        await sendMail({ inviter: currentUser.email, toEmail: value, url });
      } catch (error) {
        toast.error(error.message);
      } finally {
        toast.success('Please inform your manager to login!');
        setOpen(false);
        setValue(null);
      }
    }
    setLoading(false);
  };

  const action = (
    <>
      <Button className="mr-8" isLoading={loading} onClick={addManagerAccount}>
        Add
      </Button>
    </>
  );

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <BaseModal title="Add Manager" state={[open, setOpen]} action={action}>
      <div>
        <label htmlFor="city">
          <span>email</span>
          <div className="relative grid items-center">
            <input value={value || ''} onChange={handleChange} />
          </div>
        </label>
      </div>
      <p className="text-xl leading-loose">
        Note: the email must be primary Email, not alias!
      </p>
    </BaseModal>
  );
};

export default memo(AddManagerAuthModal);
