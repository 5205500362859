import React, { memo, useContext, useEffect, useState, useRef } from 'react';
import ModalContext from '@/contexts/ModalContext';
import { useTranslation } from 'react-i18next';
import Button from '@/components/shared/Button';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import DatabaseContext from '../contexts/DatabaseContext';
import ModalEvents from '../constants/ModalEvents';
import BaseModal from './BaseModal';
import schema from './validation';
import CompanyLogoUpload from '../components/shared/CompanyLogoUpload';

const UpdateCompanyInfoModal = () => {
  const { updateCompanyInfo } = useContext(DatabaseContext);
  const modalRef = useRef(null);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  const { emitter } = useContext(ModalContext);
  const event = ModalEvents.UPDATE_COMPANY_INFO_MODAL;
  useEffect(() => {
    const unbind = emitter.on(event, (payload) => {
      setOpen(true);
      setCompanyInfo(payload);
    });

    return () => unbind();
  }, [emitter, event]);
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await schema.validate(values);
      values.id = companyInfo.id;
      await updateCompanyInfo(values);
      modalRef.current.handleClose();
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const submitAction = (values) => (
    <Button type="submit" isLoading={loading} onClick={() => onSubmit(values)}>
      {loading ? t('shared.buttons.loading') : 'Save'}
    </Button>
  );

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={companyInfo}
        validationSchema={schema}
      >
        {({ values, handleChange, handleSubmit, resetForm, setFieldValue }) => (
          <BaseModal
            title="Update Compnay Info"
            ref={modalRef}
            state={[open, setOpen]}
            action={submitAction(values)}
            onDestroy={resetForm}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-2xl grid grid-cols-2 gap-8">
                <div>
                  <label htmlFor="city">
                    <div className="text-2xl">Name *</div>
                    <div className="relative grid items-center">
                      <input
                        id="name"
                        name="name"
                        value={values.name || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="city">
                    <div className="text-2xl">City</div>
                    <div className="relative grid items-center">
                      <input
                        id="city"
                        name="city"
                        value={values.city || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="email">
                    <div className="text-2xl">Company Email *</div>
                    <div className="relative grid items-center">
                      <input
                        id="email"
                        name="email"
                        value={values.email || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="adminEmail">
                    <div className="text-2xl">Admin Email *</div>
                    <div className="relative grid items-center">
                      <input
                        id="adminEmail"
                        name="adminEmail"
                        value={values.adminEmail || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="orgNumber">
                    <div className="text-2xl">Org Number *</div>
                    <div className="relative grid items-center">
                      <input
                        id="orgNumber"
                        name="orgNumber"
                        value={values.orgNumber || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>
                <div>
                  <label htmlFor="phone">
                    <div className="text-2xl">Phone *</div>
                    <div className="relative grid items-center">
                      <input
                        id="phone"
                        name="phone"
                        value={values.phone || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div>
                  <label htmlFor="website">
                    <div className="text-2xl">Website</div>
                    <div className="relative grid items-center">
                      <input
                        id="website"
                        name="website"
                        value={values.website || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                </div>

                <div className="col-span-2 flex gap-2 items-center">
                  <CompanyLogoUpload
                    companyId={companyInfo.id}
                    setFieldValue={setFieldValue}
                  />
                  <label htmlFor="logo" className="w-full">
                    <div className="text-2xl">Logo</div>
                    <div className="relative grid items-center">
                      <input
                        id="logo"
                        name="logo"
                        value={values.logo || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </label>
                  <AiOutlineDelete
                    size="30px"
                    className="cursor-pointer"
                    onClick={() => {
                      setFieldValue('logo', '');
                    }}
                  />
                </div>
              </div>
            </form>
          </BaseModal>
        )}
      </Formik>
    </div>
  );
};

export default memo(UpdateCompanyInfoModal);
