import { MdFileUpload } from 'react-icons/md';
import { Tooltip } from '@material-ui/core';
import React, { memo, useContext, useRef } from 'react';
import { handleKeyUp } from '../../utils';
import * as styles from './PhotoUpload.module.css';
import StorageContext from '../../contexts/StorageContext';

const CompanyLogoUpload = ({ companyId, setFieldValue }) => {
  const fileInputRef = useRef(null);
  const { uploadCompayLogoPhoto } = useContext(StorageContext);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    uploadCompayLogoPhoto(file, companyId, setFieldValue);
  };

  return (
    <div className="flex items-center">
      <Tooltip title="UploadCompanyLogo" placement="right-start">
        <div
          role="button"
          tabIndex="0"
          className={styles.circle}
          onClick={handleIconClick}
          onKeyUp={(e) => handleKeyUp(e, handleIconClick)}
        >
          <MdFileUpload size="22px" />
          <input
            name="file"
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleImageUpload}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default memo(CompanyLogoUpload);
