import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BaseModal from './BaseModal';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
import DatabaseContext from '../contexts/DatabaseContext';

const AuthModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);
  const [isLoadingMicrosoft, setIsLoadingMicrosoft] = useState(false);
  const { initNewUser } = useContext(DatabaseContext);

  const { emitter, events } = useContext(ModalContext);
  const { user, loginWithGoogle, loginWithMicrosoft, logout } =
    useContext(UserContext);

  useEffect(() => {
    const unbind = emitter.on(events.AUTH_MODAL, () => setOpen(true));
    if (isNewUser) {
      toast.success('Welcome!');
    }
    return () => unbind();
  }, [emitter, events]);

  const handleInvitation = async () => {
    await initNewUser(user.email);
    window.location.reload(true);
  };

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    const authResult = await loginWithGoogle();
    if (authResult.additionalUserInfo.isNewUser) {
      setIsNewUser(true);
    }
    setLoadingGoogle(false);
  };

  const handleSignInWithMicrosoft = async () => {
    setIsLoadingMicrosoft(true);
    await loginWithMicrosoft();
    setIsLoadingMicrosoft(false);
  };

  const handleGoToAdmin = () => {
    navigate('/app/adminboard');
    setOpen(false);
  };

  const getTitle = () =>
    user
      ? t('modals.auth.welcome', { name: user.displayName || '!' })
      : t('modals.auth.whoAreYou');

  const getMessage = () =>
    user ? t('modals.auth.loggedInText') : t('modals.auth.loggedOutText');

  const loggedInAction = (
    <>
      <Button outline className="mr-8" onClick={logout}>
        {t('shared.buttons.logout')}
      </Button>
      {user && user.adminCompanyId && (
        <Button title="" className="ml-8" onClick={handleGoToAdmin}>
          {t('landing.hero.goToAdmin')}
        </Button>
      )}
      {user &&
        !user.isSuperAdmin &&
        !user.adminCompanyId &&
        !user.managerCompanyId && (
          <Button title="" className="ml-8" onClick={handleInvitation}>
            Click here if you are invited by your company Admin!
          </Button>
        )}
    </>
  );

  const loggedOutAction = (
    <div className="flex gap-5">
      <Button isLoading={isLoadingGoogle} onClick={handleSignInWithGoogle}>
        {t('modals.auth.buttons.google')}
      </Button>
      <Button
        isLoading={isLoadingMicrosoft}
        onClick={handleSignInWithMicrosoft}
      >
        {t('modals.auth.buttons.microsoft')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title={getTitle()}
      state={[open, setOpen]}
      action={user ? loggedInAction : loggedOutAction}
    >
      <p className="text-xl leading-loose">{getMessage()}</p>
    </BaseModal>
  );
};

export default memo(AuthModal);
